import * as React from 'react';
import { RiAlarmWarningFill } from 'react-icons/ri';

import Layout from '@/components/layout/Layout';
import Seo from '@/components/Seo';
import useTranslation from 'next-translate/useTranslation';

export default function NotFoundPage() {
  const { t } = useTranslation();
  return (
    <Layout>
      <Seo title='Not Found - GINDUMAC' />
      <main>
        <section className='bg-brand-500'>
          <div className='layout flex min-h-screen flex-col items-center justify-center text-center text-black'>
            <RiAlarmWarningFill
              size={60}
              className='drop-shadow-glow animate-flicker text-red-500'
            />
            <h1
              className='mt-8 text-4xl text-white md:text-6xl'
              dangerouslySetInnerHTML={{
                __html: t('seo:PAGE404-MSG1'),
              }}
            />
            <p
              className='mt-4 text-xl text-white md:text-2xl'
              dangerouslySetInnerHTML={{
                __html: t('seo:PAGE404-MSG2'),
              }}
            />
            <div className='mt-4 flex space-x-8'>
              <a className='rounded-xl border-2 px-2 text-white' href='/'>
                {t('seo:PAGE404-MSG3')}
              </a>

              <a
                className='rounded-xl border-2 px-2 text-white'
                href={'mailto:' + t('seo:PAGE404-MSG4')}
                target='_blank'
                rel='noreferrer'
              >
                {t('seo:PAGE404-MSG5')}
              </a>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
}
